<template>
  <div>
    <v-btn class="px-1 my-2" text @click="$emit('return-calculate')">
      <v-icon>mdi-chevron-left</v-icon> Calcular novamente
    </v-btn>
    <v-card-text tag="p" class="text-subtitle-1 font-weight-medium mt-6">
      Índice recomendado: {{ feedback[type].minGood }}% -
      {{ feedback[type].maxGood }}%
    </v-card-text>
    <!-- Resultado -->
    <div class="d-flex flex-column align-center justify-center">
      <v-card-title
        tag="h2"
        class="text-center text-h5 text-sm-h4 font-weight-bold mb-2 py-0"
        style="color: #36383f !important"
      >
        Seu Resultado
      </v-card-title>
      <v-card-title
        tag="h3"
        class="text-center text-h3 text-sm-h2 font-weight-bold pt-0"
        :style="`color: ${defineFeedback.colorResult} !important`"
      >
        {{ value }}%
      </v-card-title>
      <v-alert
        :color="defineFeedback.colorAlert"
        dark
        max-width="90%"
        class="mt-2"
      >
        <template v-slot:prepend>
          <v-icon color="white" class="mr-2 mb-2">mdi-check</v-icon>
        </template>
        {{ defineFeedback.text }}
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
      require: true,
    },
    type: {
      type: String,
      default: "",
      require: true,
      validate(value) {
        return ["rotatividade", "absenteismo"].includes(value);
      },
    },
  },
  data() {
    return {
      feedback: {
        rotatividade: {
          bom: {
            text: "Parabéns! Seu índice está dentro do recomendado.",
            colorAlert: "#1aae9f",
            colorResult: "#1aae9f",
          },
          ruim: {
            text: "Seu índice de rotatividade está alto, isso pode estar acarretando prejuízos para sua empresa. Alguns dos principais prejuízos são, altos investimentos em recrutamento e seleção, despesas com demissões e a perda da capacidade produtiva resultante da baixa efetividade das equipes.",
            colorAlert: "#F7C325",
            colorResult: "#D3455B",
          },
          minGood: 15,
          maxGood: 20,
        },
        absenteismo: {
          bom: {
            text: "Parabéns! Seu índice está dentro do recomendado.",
            colorAlert: "#1aae9f",
            colorResult: "#1aae9f",
          },
          ruim: {
            text: "Seu índice está acima do tolerável. Para reduzir o absenteísmo considere melhorar a qualidade de vida e clima organizacional da sua empresa.",
            colorAlert: "#F7C325",
            colorResult: "#D3455B",
          },
          minGood: 0,
          maxGood: 2,
        },
      },
    };
  },
  computed: {
    defineFeedback() {
      if (!this.value) return this.feedback[this.type].bom;

      return this.value >= this.feedback[this.type].minGood &&
        this.value <= this.feedback[this.type].maxGood
        ? this.feedback[this.type].bom
        : this.feedback[this.type].ruim;
    },
  },
};
</script>

<style></style>
